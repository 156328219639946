import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById('root'));
const themecolor = process.env.REACT_APP_THEME_COLOR;
console.log("process.env.REACT_APP_CRM",process.env.REACT_APP_CRM)
if (process.env.REACT_APP_CRM === "true") {
 
  import("./assets/scss/dashlite.scss");
}else if(process.env.REACT_APP_PHOTOGRAPHY === "true"){
  import("./assets/scss/dashlite_blue.scss");
}else if(process.env.REACT_APP_CONSTRUCTION === "true"){
  import("./assets/scss/dashlite_orange.scss");
}
if (themecolor) {
  document.documentElement.style.setProperty("--primary-green2", themecolor);
}
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
