import Cookies from "js-cookie";
import { getCookie } from "./utils/Utils";

const BASE_URL = process.env.REACT_APP_API_URL;
const CONNECTION_URL = process.env.REACT_APP_API_CONNECTION_URL;
const token = Cookies.get("authToken");

// Example of a GET request
export const getRequest = async (route) => {
  try {
    const token = localStorage.getItem("token");

    const response = await fetch(`${BASE_URL}${route}`, {
      method: "GET",
      headers: {
        authToken: token,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Example of a POST request
export const postRequest = async (route, data, contentType = "formdata") => {
  try {
    const token = localStorage.getItem("token");

    const headers = {
      authToken: token,
      Authorization: token ? `Bearer ${token}` : undefined,
    };

    // Set content type based on input
    if (contentType === "json") {
      headers["Content-Type"] = "application/json";
      data = JSON.stringify(data);
    }

    const response = await fetch(`${BASE_URL}${route}`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken"),
      },
      body: contentType === "formdata" ? data : JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};

// Example of a PUT request
export const putRequest = async (route, data) => {
  try {
    const token = localStorage.getItem("token");

    const headers = {
      authToken: token,
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    };

    const response = await fetch(`${BASE_URL}${route}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error putting data:", error);
    throw error;
  }
};

// Example of a DELETE request
export const deleteRequest = async (route) => {
  try {
    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "application/json",
      authToken: token,
      Authorization: token ? `Bearer ${token}` : undefined,
    };

    const response = await fetch(`${BASE_URL}${route}`, {
      method: "DELETE",
      headers,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const postRequestGetUserProfile = async (route) => {
  try {
    const token = getCookie("authToken");

    const headers = {
      authToken: token,
    };

    const response = await fetch(`${BASE_URL}${route}`, {
      method: "POST",
      headers,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};

export const fetchCloudSearchApi = async (route) => {
  let response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: token,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while AppDropdown.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }
  return await response.json();
};

// Example of a POST request
export const postRequestFollow = async (route, data) => {
  try {
    const token = localStorage.getItem("token");

    const headers = {
      authToken: token,
      Authorization: token ? `Bearer ${token}` : undefined,
    };

    const response = await fetch(`${CONNECTION_URL}${route}`, {
      method: "POST",
      headers,
      body: data, // Assuming `data` is form-data or already serialized. If JSON, add Content-Type.
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};
