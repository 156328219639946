import Cookies from "js-cookie";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Spinner, Toast, ToastBody } from "reactstrap";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

export const getInitiatals = (text) => {
  const words = text.split(" ");

  // Get the first word (or an empty string if no words are present)
  const firstWord = words[0] || "";

  // Get the second word, if available, or an empty string
  const secondWord = words.length > 1 ? words[1] : "";

  // Get the first letter of the first word and capitalize it
  const firstInitial = firstWord.charAt(0).toUpperCase();

  // Get the first letter of the second word, if available, and capitalize it
  const secondInitial = secondWord.charAt(0).toUpperCase();

  // Concatenate the initials together
  return firstInitial + (secondInitial || firstWord.charAt(1).toUpperCase());
};

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

export const getCookie = (cookieName, defaultValue = "") => {
  const now = new Date();
  const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  let value = defaultValue;
  let cookieValue = Cookies.get(cookieName);
  let role = Cookies.get("role");
  let tempValue = Cookies.get(cookieName);

  if (tempValue) {
    value = tempValue;
  }
  if (!cookieValue || parseInt(role) !== 3) {
    window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
    return null;
  }
  return value;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n
    ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n)
    : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
}

export function formatTime(dateTimeString) {
  const date = new Date(dateTimeString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const timeString = hours + ":" + minutes + " " + ampm;
  return timeString;
}

export function dataURLtoFile(dataurl, filename) {
  var base64_user_image_ext = dataurl.substring(
    "data:image/".length,
    dataurl.indexOf(";base64")
  );
  var filename = filename + "." + base64_user_image_ext;
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

// //function to manage date format
// export default function formatDate(dateString) {
//   const date = new Date(dateString);
//   const day = date.getDate();
//   const month = date.getMonth() + 1;
//   const year = date.getFullYear();

//   // Add leading zeros if day or month is single digit
//   const formattedDay = day < 10 ? `0${day}` : day;
//   const formattedMonth = month < 10 ? `0${month}` : month;

//   return `${formattedDay}/${formattedMonth}/${year}`;
// }

export const CommonToaster = ({ showToast, message, toggleToast, theme }) => {
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        toggleToast(); // Automatically close after 1.5 seconds
      }, 1500); // 1.5 seconds

      return () => clearTimeout(timer); // Cleanup the timeout if the component is unmounted or re-rendered
    }
  }, [showToast, toggleToast]);

  return (
    <div
      style={{
        position: "fixed",
        top: "60px",
        right: "45px",
        zIndex: 9999, // Ensure it overlays on top of other content
        minWidth: "250px",
      }}
      className="p-3 rounded"
    >
      <Toast
        isOpen={showToast}
        style={{ backgroundColor: theme || "#333", color: "#fff" }}
      >
        <ToastBody>{message}</ToastBody>
      </Toast>
    </div>
  );
};

export function transformData({ dataArray = [], label, value, isLoading }) {
  if (isLoading) {
    return [
      {
        label: (
          <div className="d-flex justify-content-start align-items-center ms-1">
            <Spinner color="primary" size="sm" />
            <p className="ms-1">Loading...</p>
          </div>
        ),
      },
    ];
  }

  return dataArray.map((item) => ({
    label: item[label],
    value: item[value],
  }));
}

export const formatTimeForLastMessage = (timestamp) => {
  const messageDate = new Date(timestamp?.seconds * 1000);
  const now = new Date();

  // Formatters for the output
  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const dayFormatter = new Intl.DateTimeFormat("en-US", { weekday: "short" });

  // Custom date formatter for "DD-MM-YYYY"
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Check if the message is from today
  if (
    messageDate.getDate() === now.getDate() &&
    messageDate.getMonth() === now.getMonth() &&
    messageDate.getFullYear() === now.getFullYear()
  ) {
    return timeFormatter.format(messageDate); // e.g., "12:23"
  }

  // Get the start of this week (Monday)
  const dayOfWeek = now.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - daysToMonday);
  startOfWeek.setHours(0, 0, 0, 0);

  // Check if the message is from this week
  if (messageDate >= startOfWeek) {
    return dayFormatter.format(messageDate); // e.g., "Mon"
  }

  // If it's older than this week, show the date in "DD-MM-YYYY" format
  return formatDate(messageDate); // e.g., "13-10-2024"
};

export const getActiveEnvironmentVariable = () => {
  const envVariables = {
    CRM: process.env.REACT_APP_CRM === "true",
    photography: process.env.REACT_APP_PHOTOGRAPHY === "true",
    construction: process.env.REACT_APP_CONSTRUCTION === "true",
  };

  return Object.keys(envVariables).find((key) => envVariables[key]) || null;
};
