import { Button, Spinner } from "reactstrap";

const NavigationFooter = ({ formik, isLoading }) => {
  const submitFormHandler = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <>
          <Button
            color="primary"
            type="button"
            onClick={submitFormHandler}
            disabled={isLoading}
            className="btn-primary"
          >
            {isLoading ? (
              <>
                <Spinner size="sm" />
                <span> Loading... </span>
              </>
            ) : (
              <>
                <span>Save </span>
                <em className="icon ni ni-save"></em>
              </>
            )}
          </Button>
        </>
      </div>
    </>
  );
};
export default NavigationFooter;
