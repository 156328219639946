import client from "../../client.json";
import { getActiveEnvironmentVariable} from "../../utils/Utils";
 
const activeVariable = getActiveEnvironmentVariable();
const menu = [
  {
    icon: "icon ni ni-home",
    text: "Home",
    link: "/home",
  },
  {
    icon: "icon ni ni-award",
    text: `${client?.[activeVariable]?.sectin1?.map((item) => item?.title1).join(', ') }`,
    link: "/my-events",
  },
  {
    icon: "icon ni ni-user-circle",
    text: "Profile",
    link: "/profile",
  },
  {
    icon: "icon ni ni-contact",
    text: "Contact",
    link: "/contact-us",
  },
  // {
  //   icon: "chat",
  //   text: "Messanger",
  //   link: "/chat",
  // },
];
export default menu;
