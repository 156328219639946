import React, { useContext, useEffect, useMemo, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Routes, Route, useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import { Button } from "reactstrap";
import { getCookie } from "../../../../utils/Utils";
import { DataContext } from "../../../../context/DataProvider.jsx";
import Cookies from "js-cookie";
import { getPaymentSettings } from "../../../../http/getApi.js";
import { useQuery } from "react-query";

function StripePayment({
  data,
  price,
  selectedPlan,
  setModalData,
  invalidPlanData,
  setModal,
  modal,
  refetchPlandata,
  setModalTab,
  modalTab,
  client_key,
}) {
  const { setNewPlanId } = useContext(DataContext);
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");

  const { 
    data: data3, 
    refetch: refetchPaymentSettings } 
    = useQuery({
    queryKey: ["getpaymentSettings"],
    queryFn: () => getPaymentSettings(),
  });

  const stripe_public_key = data3?.data?.PaymentSetting?.find(
    (item) => item?.payment_type === "Pay with Card"
  );
  const client_key_var = stripe_public_key?.client_key || null;

  const stripePromise = useMemo(() => {
    return client_key_var ? loadStripe(client_key_var) : null;
  }, [client_key_var]);

  const prize = Cookies.get("EventVal");
  const id = Cookies.get("payerId");

  const handleBuyNow = () => {
    const formdata = new FormData();
    formdata.append("event_id", id);
    formdata.append("amount", prize);

    setNewPlanId(data?.id);

    fetch(
      `${process.env.REACT_APP_API_URL}api/v1/public/stripe-event-payment`,
      {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formdata,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.clientSecret) {
          setClientSecret(data.clientSecret);
        } else {
          console.error("Invalid clientSecret:", data);
        }
        setDpmCheckerLink(data.dpmCheckerLink || "");
      })
      .catch((error) => {
        console.error("Error creating payment intent:", error);
      });
  };

  useEffect(() => {
    refetchPaymentSettings();
    handleBuyNow();
  }, []);

  const appearance = { theme: "stripe" };
  const loader = "auto";

  return (
    <div className="App">
      {clientSecret && stripePromise ? (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          <CheckoutForm
            dpmCheckerLink={dpmCheckerLink}
            selectedPlan={selectedPlan}
            setModalData={setModalData}
            invalidPlanData={invalidPlanData}
            setModal={setModal}
            modal={modal}
            fetchPlanData={refetchPlandata}
            setModalTab={setModalTab}
            modalTab={modalTab}
          />
        </Elements>
      ) : (
        <p>Loading payment...</p>
      )}
    </div>
  );
}
export default StripePayment;
