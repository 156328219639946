import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmDeleteModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#1a1f36" }}>
        <span style={{ color: "#D3D3D3" }}>Delete User</span>
      </ModalHeader>
      <ModalBody className="text-center">
        <h5>Are you sure you want to delete this user?</h5>
        <p className="text-muted">
          User will be deleted from your recent chat list
        </p>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button
          color="primary"
          onClick={onConfirm}
          style={{ backgroundColor: "#8395a7", borderColor: "#8395a7" }}
        >
          Yes Proceed
        </Button>
        <Button
          color="secondary"
          onClick={toggle}
          style={{ backgroundColor: "#8395a7", borderColor: "#8395a7" }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmDeleteModal;
