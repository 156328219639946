import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../../Firebase Files/firebaseConfig";
import { Icon } from "../../../../components/Component";
import { getCookie } from "../../../../utils/Utils";

const ChatNotification = () => {
  const [unreadChatIds, setUnreadChatIds] = useState([]);
  const currentUserId = parseInt(getCookie("userId"));

  useEffect(() => {
    // Set up a listener to get unread messages and last message in real-time
    async function setupChatListeners() {
      try {
        const chatRoomsQuery = query(
          collection(db, "Chats"),
          where("participants", "array-contains", currentUserId)
        );

        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
        const chatIdsWithUnreadMessages = new Set();

        // Store unsubscribe functions for cleanup
        const unsubscribeFunctions = [];

        chatRoomsSnapshot.forEach((doc) => {
          const chatRoomId = doc.id;

          const unreadMessagesQuery = query(
            collection(db, "Chats", chatRoomId, "messages"),
            where("readBy", "not-in", [currentUserId]) // Use "not-in" operator for Firestore
          );

          const unsubscribeUnreadMessages = onSnapshot(
            unreadMessagesQuery,
            (snapshot) => {
              // Filter messages where currentUserId is NOT in the readBy array
              const filteredUnreadMessages = snapshot.docs.filter(
                (doc) => !(doc.data().readBy || []).includes(currentUserId)
              );

              if (filteredUnreadMessages.length > 0) {
                chatIdsWithUnreadMessages.add(chatRoomId);
              } else {
                chatIdsWithUnreadMessages.delete(chatRoomId);
              }

              setUnreadChatIds(chatIdsWithUnreadMessages.size);

              return () => {
                unsubscribeUnreadMessages();
              };
            }
          );
        });
      } catch (error) {
        console.error("Error setting up listeners for chats:", error);
      }
    }

    setupChatListeners();
  }, [currentUserId]);

  return (
    <div className="position-relative align-center">
      <Icon
        name="chat"
        className="size-md"
        style={{ fontSize: "1.5rem", cursor: "pointer" }}
      />
      {unreadChatIds > 0 && (
        <span
          className="position-absolute bg-danger border border-light rounded-circle d-flex align-items-center justify-content-center"
          style={{
            top: "-0.55rem",
            right: "-0.55rem",
            width: "1.5rem", // Adjust width and height for badge size
            height: "1.5rem",
            fontSize: "0.65rem", // Adjust font size for badge content
            color: "white",
            padding: "10px",
          }}
        >
          {unreadChatIds}
        </span>
      )}
    </div>
  );
};

export default ChatNotification;
