import React, { useRef, useState } from "react";
import { Button } from "reactstrap";
import Contactus from "../../assets/images/ContactImage.png";
import Required from "../../components/Required";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { getActiveEnvironmentVariable, getCookie } from "../../utils/Utils";
import "react-toastify/dist/ReactToastify.css";
import conblue from "../../images/contactusblue.webp";
import conorg from "../../images/org4.jpg";


const Contact = () => {
  const recaptcha = useRef();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const activeVariable = getActiveEnvironmentVariable();
  const [errors, setErrors] = useState({});

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    console.log("Captcha token:", token);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    } else if (/\d/.test(formData.name)) {
      newErrors.name = "Name should not contain numbers.";
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      newErrors.name = "Name should not contain special characters.";
    }

    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!formData.subject) {
      newErrors.subject = "Subject is required.";
    }

    if (!formData.message) {
      newErrors.message = "Message is required.";
    } else if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters.";
    }

    if (!recaptchaToken) {
      newErrors.recaptcha = "Please verify the reCAPTCHA.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
      return;
    }
    setLoading(true);
    try {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("message", formData.message);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/add-contactUs`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: data, 
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("API Response:", responseData);

      toast.success("Data Added Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { width: "20rem" },
      });

      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      recaptcha.current.reset();
      setRecaptchaToken(null);
    } catch (error) {
      console.error("Error storing the contact data:", error);
      toast.error("Failed to submit data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact m-3">
      <div className="container bg-white">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 text-center p-3">
            <h3 className="text-center" style={{ marginTop: "50px" }}>
              Contact Us
            </h3>
            <img src={activeVariable =="CRM"? Contactus : activeVariable =="photography" ? conblue : conorg} className="img-fluid" alt="Contact Us" />
          </div>
          <div className="col-12 col-lg-6 mt-5">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name:
                  <Required />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter Your Name."
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email:
                  <Required />
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="text-danger">{errors.email}</p>}
              </div>
              <div className="mb-3">
                <label htmlFor="subject" className="form-label">
                  Subject:
                  <Required />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
                {errors.subject && (
                  <p className="text-danger">{errors.subject}</p>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message:
                  <Required />
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Write your message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="3"
                />
                {errors.message && (
                  <p className="text-danger">{errors.message}</p>
                )}
              </div>
              <div className="md:flex md:items-center mx-auto">
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
                {errors.recaptcha && (
                  <p className="text-danger">{errors.recaptcha}</p>
                )}
                <Button
                  color="primary"
                  type="submit"
                  className="mt-2 mb-2 btn-primary"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
