import {
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  deleteDoc,
  arrayRemove,
  writeBatch,
} from "firebase/firestore";
import { db, storage } from "./firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import { getCookie } from "../utils/Utils";

export const updateUserStatus = async (
  userId,
  status,
  avatarUrl,
  newConvoItem
) => {
  try {
    const userRef = doc(db, "Users", userId.toString());
    await updateDoc(userRef, {
      active: status,
      profilePic: avatarUrl, // Add/update the profilePic field
      convo: arrayUnion(newConvoItem), // Add the new convo item to the convo array
    });
    console.log(`User ${userId} status updated, and convo added`);
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

export const updateUserVisibility = async (userId, visibility) => {
  try {
    const userRef = doc(db, "Users", userId.toString());

    // Check if the user exists first
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      console.log("User does not exist, can't update visibility.");
      return;
    }

    // Update user visibility
    await updateDoc(userRef, {
      visible: visibility,
    });

    console.log(`User ${userId} visibility updated to ${visibility}`);
  } catch (error) {
    console.error("Error updating user visibility:", error);
  }
};

export const updateUserConvo = async (userId, newConvoItem) => {
  try {
    const userRef = doc(db, "Users", userId.toString());
    await updateDoc(userRef, {
      convo: arrayUnion(newConvoItem), // Add the new convo item to the convo array
    });
    console.log(`User ${userId} status updated, and convo added`);
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

// Function to delete the chatroom between the current user and a contact
// export const deleteChatRoom = async (currentUserId, contactId) => {
//   if (!currentUserId || !contactId) {
//     console.error("Invalid user or contact ID");
//     return;
//   }

//   // Query the Chats collection to find a chat that includes both participants
//   const q = query(
//     collection(db, "Chats"),
//     where("participants", "array-contains", currentUserId)
//   );

//   const querySnapshot = await getDocs(q);
//   let chatId = null;

//   querySnapshot.forEach((doc) => {
//     const participants = doc.data().participants;
//     if (participants.includes(contactId)) {
//       chatId = doc.id; // Capture the chat ID for deletion
//     }
//   });

//   // Delete the chatroom if it exists
//   if (chatId) {
//     try {
//       await deleteDoc(doc(db, "Chats", chatId));
//       console.log(`Chatroom with ID ${chatId} deleted successfully.`);
//     } catch (error) {
//       console.error("Error deleting chatroom:", error);
//     }
//   } else {
//     console.log("No chatroom found between the current user and the contact.");
//   }
// };

export const updateChattingIds = async (userId, chatId) => {
  try {
    const userRef = doc(db, "Users", userId.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Check if chattingIds array exists and if chatId is already present
      if (!userData.chattingIds || !userData.chattingIds.includes(chatId)) {
        await updateDoc(userRef, {
          chattingIds: arrayUnion(chatId), // Add the new chatId if not present
        });
        console.log(`User ${userId} status updated, and chatId added`);
      } else {
        console.log(
          `chatId ${chatId} already exists in chattingIds for user ${userId}`
        );
      }
    } else {
      console.log(`User ${userId} does not exist.`);
    }
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

export const removeChattingId = async (
  otherUserDocId,
  otherUsersId,
  currentUsersDocId,
  currentUsersId
) => {
  try {
    // Reference to other user's document
    const userRef = doc(db, "Users", otherUserDocId.toString());
    const userDoc = await getDoc(userRef);

    // Reference to current user's document
    const currentUserRef = doc(db, "Users", currentUsersDocId.toString());
    const currentUserDoc = await getDoc(currentUserRef);

    // Remove currentUsersId from other user's chattingIds
    if (userDoc.exists()) {
      const userData = userDoc.data();
      if (
        userData.chattingIds &&
        userData.chattingIds.includes(currentUsersId)
      ) {
        await updateDoc(userRef, {
          chattingIds: arrayRemove(currentUsersId),
        });
      } else {
        console.log(
          `currentUsersId ${currentUsersId} not found in chattingIds for user ${otherUsersId}`
        );
      }
    } else {
      console.log(`User ${otherUsersId} does not exist.`);
    }

    // Remove userId from current user's chattingIds
    if (currentUserDoc.exists()) {
      const currentUserData = currentUserDoc.data();
      if (
        currentUserData.chattingIds &&
        currentUserData.chattingIds.includes(otherUsersId)
      ) {
        await updateDoc(currentUserRef, {
          chattingIds: arrayRemove(otherUsersId),
        });
        console.log(
          `otherUsersId ${otherUsersId} removed from chattingIds for current user ${currentUsersId}`
        );
      } else {
        console.log(
          `otherUsersId ${otherUsersId} not found in chattingIds for current user ${currentUsersId}`
        );
      }
    } else {
      console.log(`Current user ${currentUsersId} does not exist.`);
    }
  } catch (error) {
    console.error("Error removing chatId:", error);
  }
};

export const checkUserExists = async (userId) => {
  const userDoc = doc(db, "Users", userId); // Adjust based on your structure
  const docSnap = await getDoc(userDoc);
  return docSnap.exists();
};

export const handleDownloadFile = async (filePath, userId) => {
  // Check if the user exists in the Users collection
  const userDoc = doc(db, "Users", userId);
  const docSnap = await getDoc(userDoc);

  if (!docSnap.exists()) {
    console.log("User does not exist in Users collection!");
    return null; // User does not exist, abort download
  }

  // User exists, proceed to get the download URL
  const fileRef = ref(storage, filePath);

  try {
    const downloadURL = await getDownloadURL(fileRef);
    console.log("Download URL:", downloadURL);
    return downloadURL; // Return the download URL
  } catch (error) {
    console.error("Error fetching download URL:", error);
    return null; // Handle the error as needed
  }
};

//function to clear chat from the one side ( not from the database)
// export const ClearChat = async (chatRoomId, currentUserId) => {
//   const messagesRef = collection(db, "Chats", chatRoomId, "messages");

//   const q = query(messagesRef);

//   try {
//     // Fetch all messages in the chat room
//     const querySnapshot = await getDocs(q);

//     // Create a batch for writing multiple updates
//     const batch = writeBatch(db);

//     // Loop through all the messages
//     querySnapshot.forEach((docSnapshot) => {
//       const messageId = docSnapshot.id;
//       const messageData = docSnapshot.data();

//       // Check if the currentUserId is already in the 'clearedBy' array
//       if (!messageData.clearedBy.includes(currentUserId)) {
//         // If not, add currentUserId to the clearedBy array
//         const messageRef = doc(db, "Chats", chatRoomId, "messages", messageId);

//         // Update the document using the arrayUnion method to add currentUserId
//         batch.update(messageRef, {
//           clearedBy: arrayUnion(currentUserId), // Adds the user ID to the array if it's not already present
//         });
//       }
//     });

//     // Commit the batch update
//     await batch.commit();
//     console.log(`Chat cleared successfully by user ${currentUserId}`);
//   } catch (error) {
//     console.error("Error clearing chat:", error);
//   }
// };

export const ClearChat = async (
  currentUserId,
  otherUserId,
  currentUserName
) => {
  const chatsRef = collection(db, "Chats");

  try {
    // Query the Chats collection to find the chatRoomId
    const q = query(
      chatsRef,
      where("participants", "array-contains", currentUserId)
    );

    // Fetch all chat documents where currentUserId is a participant
    const querySnapshot = await getDocs(q);

    // Find the correct chatRoomId by checking the participants array
    let chatRoomId = null;
    querySnapshot.forEach((docSnapshot) => {
      const chatData = docSnapshot.data();
      if (chatData.participants.includes(otherUserId)) {
        chatRoomId = docSnapshot.id; // The document ID is the chatRoomId
      }
    });

    if (!chatRoomId) {
      console.warn("Chat room not found for the given participants.");
      return;
    }

    // Reference the messages in the found chat room
    const messagesRef = collection(db, "Chats", chatRoomId, "messages");

    // Fetch all messages in the chat room
    const messagesQuery = query(messagesRef);
    const messagesSnapshot = await getDocs(messagesQuery);

    // Create a batch for writing multiple updates
    const batch = writeBatch(db);

    // Loop through all the messages
    messagesSnapshot.forEach((docSnapshot) => {
      const messageId = docSnapshot.id;
      const messageData = docSnapshot.data();

      // Check if the currentUserId is already in the 'clearedBy' array
      if (!messageData.clearedBy?.includes(currentUserId)) {
        // If not, add currentUserId to the clearedBy array
        const messageRef = doc(db, "Chats", chatRoomId, "messages", messageId);

        // Update the document using the arrayUnion method to add currentUserId
        batch.update(messageRef, {
          clearedBy: arrayUnion(currentUserId),
        });
      }
    });

    const CurrentUsersDocId = `${currentUserName}_${currentUserId}`;

    const currentUserRef = doc(db, "Users", CurrentUsersDocId);
    const currentUserDoc = await getDoc(currentUserRef);

    // Remove OtherUsersId from current user's chattingIds
    if (currentUserDoc.exists()) {
      const currentUserData = currentUserDoc.data();
      if (
        currentUserData.chattingIds &&
        currentUserData.chattingIds.includes(otherUserId)
      ) {
        await updateDoc(currentUserRef, {
          chattingIds: arrayRemove(otherUserId),
        });
      }
    }

    // Commit the batch update
    await batch.commit();
  } catch (error) {
    console.error("Error clearing chat:", error);
  }
};

export const updateFirebaseUserDetails = async (profile_Pic) => {
  const user_Id = getCookie("userId");
  const username = getCookie("username");
  const compositeId = `${username}_${user_Id}`;

  try {
    const userRef = doc(db, "Users", compositeId.toString());

    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      return;
    }

    await updateDoc(userRef, {
      profilePic: profile_Pic,
    });
  } catch (error) {
    console.error("Error updating user profilePic:", error);
  }
};
