import React, { useContext, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { DataContext } from "../../../../context/DataProvider.jsx";
import { getCookie } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

function CheckoutForm({
  selectedPlan,
  setModalData,
  invalidPlanData,
  setModal,
  modal,
  fetchPlanData,
  setModalTab,
  modalTab,
}) {
  const { newPlanId, transactionid, setTransactionId } =
    useContext(DataContext);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(getCookie("username"));
  const [email, setEmail] = useState(getCookie("email"));
  const [transactionid1, setTransactionid1] = useState(null);



  // const [phone, setPhone] = useState(getCookie("phone_number"));

  // const handleEmailChange = (e) => {
  //   const value = e.target.value;
  //   setEmail(value);

  //   // Custom email validation on every change
  //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //   // Validate email format on change
  //   if (value && !emailRegex.test(value)) {
  //     alert("Please enter a valid email address.");
  //   } else {
  //     alert(""); // Clear error if the email is valid
  //   }
  // };

  const prize = Cookies.get("EventVal");
  const id = Cookies.get("payerId");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/plan-selection",
        return_url: `${process.env.REACT_APP_API_URL}plan-selection`,
        // payment_method_data: {
        //   billing_details: {
        //     name: name,
        //     email: email,
        //     // phone: phone,
        //   },
        // },
      },
      redirect: "if_required",
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent?.status === "succeeded") {
      console.log("paymentIntent", paymentIntent.id);
      setTransactionid1(paymentIntent.id);
      // console.log("Pa");

      console.log("paymentIntent.id", paymentIntent.id);
      try {
        handleSubmitSubscribePayment(newPlanId, paymentIntent.id);
      } catch (err) {
        console.error("Error subscribing to plan:", err);
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };
  
  const handleSubmitSubscribePayment = async (newPlanId, transactionid) => {
    // if (transactionid1 != null) {
    const plan_id = selectedPlan?.id ? selectedPlan?.id : newPlanId;
    const note = selectedPlan?.name + " plan purchased";

    try {
      const formData = new FormData();
      // console.log();
      // formData.append("payer_id", "1");
      // formData.append("payment_mode", "3");
      // formData.append("note", note);
      formData.append("event_id", id);
      formData.append("purpose", "1");
      formData.append("mode", "1");
      formData.append("status", "3");
      formData.append("payment_txn_id", transactionid);
      formData.append("amount", prize);
      formData.append("payment_response", "payment_response");

      // const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-package`, {
      const planData = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-stripe-event-payment`,
        {
          method: "POST",
          body: formData,
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      const planDataResponse = await planData.json();
      if (planDataResponse && planDataResponse.status) {
        Swal.fire({
          icon: "success",
          title: planDataResponse.message,
          focusConfirm: false,
        });
        console.log("In to the IF ");

        const submittedData = planData?.data;
        // const planData1 = await planData.json();
        // setModalData(planDataResponse);

        invalidPlanData();
        setTimeout(() => {
          setModal(!modal);
          fetchPlanData();
          setModalTab(!modalTab);
        }, 1000);
      } else {
        toast.error(planData.message);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
    }
    // }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        {/* <div className="mb-3" id="payment-element">
        <label htmlFor="name" className="form-label">Card Holder Name</label>
        <input
          type="text"
          maxLength={255}
          placeholder="Enter Card Holder Name"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="form-control"
        />
      </div>

      <div className="mb-3" id="payment-element">
        <label htmlFor="email" className="form-label">Email</label>
        <input
          type="email"
          placeholder="Enter Email"
          maxLength={255}
          id="email"
          value={email}
          onChange={handleEmailChange}
          // onChange={(e) => setEmail(e.target.value)}
          required
          className="form-control"
        />
      </div> */}

        <div className="d-flex justify-content-center mt-3">
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="btn-primary"
          >
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay Now"
              )}
            </span>
          </button>
        </div>

        {message && <div id="payment-message">{message}</div>}
      </form>
      <div id="dpm-annotation">
        {/* <p>
          <a className="" href={dpmCheckerLink} target="_blank" rel="noopener noreferrer" id="dpm-integration-checker">Preview payment methods by transaction</a>
        </p> */}
      </div>
    </>
  );
}
export default CheckoutForm;
