import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useParams } from "react-router";
import CardPay from "./CardPay";
import Paypal from "./Paypal";
import { Icon } from "../../../../components/Component";
import classNames from "classnames";
import { useQuery } from "react-query";
import {
  getPaymentSettings,
  getUpcomingEventsList,
  paymentListData,
} from "../../../../http/getApi";
import PaymentDetails from "../EventDetails/PaymentDetails";
import { formatDate } from "../../../../utils/Utils";
import StripePayment from "./StripePayment";
import { ShimmerBadge, ShimmerButton } from "react-shimmer-effects";

const Payment = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  //fetching payment data
  const {
    data: paymentInfo,
    isLoading: paymentInfoLoading,
    isError: paymentInfoError,
  } = useQuery({
    queryKey: ["get-payment-list-id"],
    queryFn: () => getPaymentSettings(),
    staleTime: Infinity,
  });

  console.log("paymentInfo:", paymentInfo);

  const [activeTab, setActivetab] = useState();

  useEffect(() => {
    setActivetab(
      paymentInfoLoading
        ? ""
        : paymentInfoError
        ? ""
        : paymentInfo?.data?.PaymentSetting?.[0]?.id
    );
  }, [paymentInfo]);

  const toggleIconTab = (tab) => {
    if (activeTab !== tab) {
      setActivetab(tab);
    }
  };

  //fetching event data from id
  const {
    data: upcomingEvent,
    isLoading: upcomingEventLoading,
    isError: upcomingEventsError,
  } = useQuery({
    queryKey: ["get-events-by-id", id],
    queryFn: () =>
      getUpcomingEventsList({ id: id, page: "", limit: "", search: "" }),
    staleTime: Infinity,
  });

  const contact_number =
    upcomingEvent?.data?.[0]?.client_details?.[0]?.work_phone_number;
  const packagePrice =
    upcomingEvent?.data?.[0]?.package_details?.[0]?.package_price;
  const retainerPrice =
    upcomingEvent?.data?.[0]?.event_financials?.retainer_value;
  const addOnTotal = upcomingEvent?.data?.[0]?.event_financials?.addon_total;
  const travelFee = upcomingEvent?.data[0]?.venue_details[0]?.travel_cost || 0;
  const discount1 = upcomingEvent?.data[0]?.event_financials?.discount1 || 0;
  const discount2 = upcomingEvent?.data[0]?.event_financials?.discount2 || 0;
  const balanceDueAfterRetainer =
    upcomingEvent?.data[0]?.event_financials?.balance_due_after_retainer || 0;
  const totalFee =
    balanceDueAfterRetainer +
    upcomingEvent?.data?.[0]?.event_financials?.retainer_value;
  const scheduledPayment2 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2;
  const scheduledPayment3 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3;
  const scheduledPayment2Date = formatDate(
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2_txn
  );
  const scheduledPayment3Date = formatDate(
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3_txn
  );
  const eventFinancials = upcomingEvent?.data?.[0]?.event_financials;

  return (
    <>
      <div className="mt-3 px-2">
        <h4>Make Your Payment</h4>
        <div class="card card-preview mt-3">
          <div className="card-inner pt-0">
            <Nav tabs>
              {/* <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <em class="icon ni ni-cc-alt-fill"></em>
                  <span>Payment Details</span>
                </NavLink>
              </NavItem> */}
              <NavItem className="align-center">
                {paymentInfoLoading ? (
                  <ShimmerButton
                    size="md"
                    width={100}
                    style={{ margin: "6px 0px" }}
                  />
                ) : paymentInfoError ? (
                  "Error..."
                ) : (
                  paymentInfo &&
                  paymentInfo?.data?.PaymentSetting.map((item, idx) => (
                    <span key={idx}>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classNames ({
                          active: activeTab === item?.id,
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab(item?.id);
                        }}
                      >
                        {item?.payment_type === "Pay with Card" ? (
                          ""
                        ) : item?.payment_type === "Paypal" ? (
                          <em class="icon ni ni-paypal-alt"></em>
                        ) : (
                          ""
                        )}
                        <span className="px-3">
                          {item?.payment_type === "Pay with Card"
                            ? "Pay With Card"
                            : item?.payment_type === "Paypal" 
                            ? "Paypal"
                            : ""}
                        </span>
                      </NavLink>
                    </span>
                  ))
                )}
              </NavItem>
              {/* <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <em class="icon ni ni-paypal-alt"></em> <span>Paypal</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "2" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <span>Pay With Card</span>
                </NavLink>
              </NavItem> */}
            </Nav>

            <TabContent activeTab={activeTab}>
              {/* <TabPane tabId="1">
                <PaymentDetails
                  toggleMain={toggleIconTab}
                  mainCurrentTab={activeTab}
                  upcomingEvent={upcomingEvent}
                  packagePrice={packagePrice}
                  retainerPrice={retainerPrice}
                  addOnTotal={addOnTotal}
                  travelFee={travelFee}
                  discount1={discount1}
                  discount2={discount2}
                  balanceDueAfterRetainer={balanceDueAfterRetainer}
                  totalFee={totalFee}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  scheduledPayment2Date={scheduledPayment2Date}
                  scheduledPayment3Date={scheduledPayment3Date}
                  eventFinancials={eventFinancials}
                />
              </TabPane> */}
              {paymentInfo?.data?.PaymentSetting.map((item, idx) => (
                <TabPane tabId={item?.id} key={idx}>
                  {item?.payment_type === "Pay with Card" ? (
                    <StripePayment
                      toggleMain={toggleIconTab}
                      mainCurrentTab={activeTab}
                    />
                  ) : item?.payment_type === "Paypal" ? (
                    <Paypal
                      toggleMain={toggleIconTab}
                      mainCurrentTab={activeTab}
                      id={id}
                      upcomingEvent={upcomingEvent}
                      contact_number={contact_number}
                    />
                  ) : (
                    ""
                  )}
                </TabPane>
              ))}
              {/* <TabPane tabId="2">
              <StripePayment
                  toggleMain={toggleIconTab}
                  mainCurrentTab={activeTab}
                />
              </TabPane> */}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
